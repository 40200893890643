import FormPage from '@/components/FormPage'
import { DateTime } from 'luxon'
import {
  formatAddressFamily,
  formatAddressForeigner,
  formatPhoneNumber,
  getNumeralFromRm,
  getRm,
  getStateFromMyKad,
  mnpIcon,
  mnpClass,
  stringGen,
} from '@/utils'
import {
  createGetParams,
  createPostParams,
} from '@/rest'

const formTitle = 'Register Family Subscriber'

export default {
  extends: FormPage,
  props: ['id'],
  data () {
    return {
      agree: false,
      notification: false,
      billingAddress: {
        address1: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: 'Street',
          props: {
            label: 'Enter street',
            required: true,
            rules: [val => !!val || 'Street required!'],
            validateOnBlur: true,
          },
        },
        address2: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: '',
          props: {
            label: 'Enter line 2 of street',
            rules: [],
          },
        },
        city: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: 'City',
          props: {
            label: 'Enter city',
            required: true,
            rules: [val => !!val || 'City required!'],
          },
        },
        postcode: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: 'Postal code',
          props: {
            label: 'Enter postal code',
            mask: '#####',
            maxlength: 5,
            required: true,
            rules: [
              val => !!val || 'Postal code required!',
              val => (val && val.length === 5) || 'Invalid postal code!',
            ],
            validateOnBlur: true,
          },
        },
        state: {
          component: () => import(/* webpackChunkName: "stateProvinceField" */ '@/components/StateProvinceField'),
          props: {
            required: true,
            rules: [val => !!val || 'State required!'],
          },
          name: 'State/Province',
        },
      },
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      debounce: 1500,
      searchTimeout: null,
      step: 1,
      title: formTitle,
      // Form steps
      registrationDetails: {
        fields: {
          mnpStatusInfo: {
            component: () => import(/* webpackChunkName: "mnpStatusInfo" */ '@/components/MnpStatusInfo'),
            name: 'Mnp Status',
            info: null,
          },
          erecharge: {
            name: 'E-recharge balance',
          },
          ugu: {
            component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
            name: 'User-Gets-User',
            props: {
              existing: true,
              hint: 'Mobile number of the referrer',
              label: 'Enter UGU',
              noSelf: true,
              noDealer: true,
              ugu: true,
            },
          },
          principal: {
            name: 'Principal Msisdn',
          },
          donor: {
            component: () => import(/* webpackChunkName: "mnpDonorField" */ '@/components/MnpDonorField'),
            name: 'Donor`s msisdn',
            props: {
              required: true,
              rules: [val => !!val || 'Donor required!'],
            },
            value: this.donor,
          },

          msisdnList: {
            name: 'Msisdn list',
            component: () => import(/* webpackChunkName: "msisdnListField2" */ '@/components/MsisdnListField2'),
            props: {
              mnpResubmit: false,
              items: [],
            },
          },
          requiredEcash: {
            name: 'Required e-recharge',
            strong: true,
          },
        },
        subTitle: 'Mobile number selections',
        title: 'Registration Details',
      },
      profile: {
        fields: {
          uploadMyKad: {
            name: 'File upload',
            component: () => import(/* webpackChunkName: "nricImageUploadField" */ '@/components/NricImageUploadField'),
          },
          /*
          ocr: {
            name: 'Ocr',
            component: () => import('@/components/OcrField'),
            props: {
              step: 1
            }
          },
          */
          title: {
            component: () => import(/* webpackChunkName: "titleField" */ '@/components/TitleField'),
            name: 'Title',
            props: {
              rules: [val => !!val || 'Title required!'],
            },
          },
          name: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Full name',
            props: {
              hint: 'Exactly as in Id card',
              label: 'Enter full name',
              persistentHint: true,
              required: true,
              rules: [
                val => !!val || 'Full name required!',
              ],
            },
          },
          subid: {
            component: () => import(/* webpackChunkName: "personalIdField" */ '@/components/PersonalIdField'),
            name: 'MyKad',
            props: {
              label: 'Enter MyKad number',
              // hint: 'MyKad/Passport/Armed Force number',
              rules: [
                val => !!val || 'MyKad number required!',
                val => (val && val.length === 12) || 'Invalid MyKad',
              ],
            },
          },
          dob: {
            component: () => import(/* webpackChunkName: "dobField" */ '@/components/DobField'),
            name: 'Date of birth',
            props: {
              required: true,
            },
          },
          gender: {
            component: () => import(/* webpackChunkName: "genderField" */ '@/components/GenderField'),
            name: 'Gender',
          },
          race: {
            component: () => import(/* webpackChunkName: "raceField" */ '@/components/RaceField'),
            name: 'Race',
          },
          maidenName: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            hide: false,
            name: 'Mother maiden name',
            props: {
              hint: 'Name before she got married',
              label: 'Enter mother maiden name',
              persistentHint: true,
            },
          },
          fcountry: {
            name: 'Country',
            hide: true,
          },
          fstatus: {
            component: () => import(/* webpackChunkName: "statusField" */ '@/components/StatusField'),
            name: 'Foreigner status',
            hide: true,
            props: {
              list: [
                { text: 'Tourist', value: 'T' },
                { text: 'Student', value: 'S' },
                { text: 'Worker', value: 'W' }],
              itemValue: 'value',
              itemText: 'text',
              label: 'Select status foreigner',
              returnObject: true,
              persistentHint: true,
              required: true,
            },
          },
        },
        subTitle: 'Subscriber identity',
        title: 'Profile',
      },
      contactDetails: {
        fields: {
          contactNumber: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Contact number',
            props: {
              hint: 'Contactable land or mobile number',
              label: 'Enter phone number',
              required: true,
              rules: [
                val => !!val || 'Contact number required!',
              ],
            },
          },
          billDelivery: {
            component: () => import(/* webpackChunkName: "billDeliveryField" */ '@/components/BillDeliveryField'),
            name: 'Bill delivery',
          },
          email: {
            // component: () => import(/* webpackChunkName: "emailField" */ '@/components/EmailField'),
            component: () => import(/* webpackChunkName: "unrestrictedEmailField" */ '@/components/UnrestrictedEmailField'),
            name: 'Email',
            props: {
              clearable: true,
              label: 'Enter email address',
              rules: [],
              validateOnBlur: true,
              required: true,
            },
            validate: () => {},
          },
          address1: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Street',
            props: {
              label: 'Enter street',
              required: true,
              rules: [val => !!val || 'Street required!'],
              validateOnBlur: true,
            },
            section: 'Address on MyKad',
          },
          address2: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: '',
            props: {
              label: 'Enter line 2 of street',
            },
          },
          city: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'City',
            props: {
              label: 'Enter city',
              required: true,
              rules: [val => !!val || 'City required!'],
            },
          },
          postcode: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Postal code',
            props: {
              label: 'Enter postal code',
              mask: '#####',
              maxlength: 5,
              required: true,
              rules: [
                val => !!val || 'Postal code required!',
                val => (val && val.length === 5) || 'Invalid postal code!',
              ],
              validateOnBlur: true,
            },
          },
          state: {
            component: () => import(/* webpackChunkName: "stateProvinceField" */ '@/components/StateProvinceField'),
            name: 'State',
            props: {
              required: true,
              rules: [val => !!val || 'State required!'],
            },
          },
          billingAddress: {
            component: () => import(/* webpackChunkName: "addressField" */ '@/components/AddressField'),
            fields: {},
            name: 'Billing address',
          },
          fstreet1: {
            name: 'Street',
            hide: true,
          },
          fstreet2: {
            name: 'Street2',
            hide: true,
          },
          fcity: {
            name: 'City',
            hide: true,
          },
          fpostalCode: {
            name: 'Postal code',
            hide: true,
          },
          fstate: {
            name: 'State',
            hide: true,
          },
        },
        subTitle: 'Contact & billing information',
        title: 'Contact details',
      },
      submission: {
        fields: {
          ugu: {
            name: 'UGU',
          },
          principal: {
            name: 'Principal Msisdn',
          },
          donor: {
            name: 'Donor',
          },
          title: {
            name: 'Title',
          },
          name: {
            name: 'Name',
          },
          subid: {
            name: 'MyKad',
          },
          dob: {
            name: 'Date of birth',
          },
          gender: {
            name: 'Gender',
          },
          race: {
            name: 'Race',
          },
          maidenName: {
            name: 'Mother maiden name',
          },
          contactNumber: {
            name: 'Contact number',
          },
          billDelivery: {
            name: 'Bill delivery',
          },
          email: {
            name: 'Email',
          },
          address: {
            name: 'Address',
          },
          billingAddress: {
            name: 'Billing address',
          },
          fcountry: {
            name: 'Issue country',
            hide: true,
          },
          fstatus: {
            name: 'Status',
            hide: true,
          },
          foreignerAddress: {
            name: 'Foreigner address',
            hide: true,
          },
          msisdnSummary: {
            name: 'Msisdn list',
            component: () => import(/* webpackChunkName: "msisdnSummaryField2" */ '@/components/MsisdnSummaryField2'),
            props: {
              items: [],
            },
          },
          chargeable: {
            emphasize: true,
            name: 'Chargeable amount',
          },
        },
        submit: false,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    // chargeableAmount: function () {
    //   let chargeableAmount = 0.00
    //   // this.registrationDetails.fields.msisdnList.props.items.forEach(item => {
    //   //   chargeableAmount += Number(this.price)
    //   // })
    //   this.registrationDetails.fields.msisdnList.props.items.forEach(item => {
    //     if (this.mainMsisdn === item.msisdn) {
    //       chargeableAmount += Number(39)
    //     } else {
    //       chargeableAmount += Number(10)
    //     }
    //   })
    //   return chargeableAmount
    // },

    chargeableAmount: function () {
      let chargeableAmount = 0.00
      this.registrationDetails.fields.msisdnList.props.items.forEach(item => {
        if (this.plan === 'SISWA_PLUS') {
          if (this.mainMsisdn === item.msisdn) {
            chargeableAmount += Number(35)
          } else {
            chargeableAmount += Number(10)
          }
        } else {
          if (this.mainMsisdn === item.msisdn) {
            chargeableAmount += Number(39)
          } else {
            chargeableAmount += Number(10)
          }
        }
      })
      return chargeableAmount
    },
    erecharge: function () {
      return this.registrationDetails.fields.erecharge
    },
    foreignerAddress () {
      const foreignerAddress = formatAddressForeigner(this.contactDetails.fields)
      return foreignerAddress
    },
    foreignerStatus: function () {
      return this.profile.fields.fstatus.value
    },
    // Override
    formSteps: function () {
      return {
        registrationDetails: this.registrationDetails,
        profile: this.profile,
        contactDetails: this.contactDetails,
        submission: this.submission,
      }
    },
    registrationItems: function () {
      return this.registrationDetails.fields.msisdnList.props.items
    },
    requiredEcash: function () {
      let requiredEcash = 0.00
      this.registrationDetails.fields.msisdnList.props.items.forEach(item => {
        if (this.plan === 'SISWA_PLUS') {
          if (this.mainMsisdn === item.msisdn || this.mainMsisdn === '') {
            requiredEcash += Number(25)
          } else {
            requiredEcash += Number(10)
          }
        } else {
          if (this.mainMsisdn === item.msisdn || this.mainMsisdn === '') {
            requiredEcash += Number(29)
          } else {
            requiredEcash += Number(10)
          }
        }
      })
      return requiredEcash
    },
    address () {
      const address = formatAddressFamily(this.contactDetails.fields)
      if (!this.contactDetails.fields.billingAddress.value) {
        this.submission.fields.billingAddress.value = address
      }
      return address
    },
    billToAddress () {
      return formatAddressFamily(this.contactDetails.fields.billingAddress.fields)
    },
  },
  watch: {
    agree: function (val) {
      val && this.doSubmit()
    },
    step: function (val) {
      this.profile.fields.ocr.props.step = val
    },
    'registrationDetails.fields.ugu.value': function (val) {
      this.submission.fields.ugu.value = formatPhoneNumber(val)
    },
    'registrationDetails.fields.principal.value': function (val) {
      this.submission.fields.principal.value = formatPhoneNumber(val)
    },
    'registrationDetails.fields.donor.value': function (val) {
      this.submission.fields.donor.value = val
    },
    'profile.fields.title.value': function (val) {
      this.submission.fields.title.value = val
    },
    'profile.fields.name.value': function (val) {
      this.submission.fields.name.value = val
    },
    'profile.fields.dob.value': function (val) {
      this.submission.fields.dob.value = val
    },
    'profile.fields.maidenName.value': function (val) {
      this.submission.fields.maidenName.value = val
    },
    'contactDetails.fields.contactNumber.value': function (val) {
      this.submission.fields.contactNumber.value = val
    },
    'contactDetails.fields.email.value': function (val) {
      this.submission.fields.email.value = val
    },
    'contactDetails.fields.billDelivery.value': function (val) {
      if (val === 'email') {
        this.submission.fields.billDelivery.value = 'Bill via email'
        this.contactDetails.fields.email.props.required = true
        this.contactDetails.fields.email.props.rules.push(
          val => !!val || 'Email required!',
          val => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(val) || 'Invalid email!',
          // val => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val) || 'Invalid email!'
        )
      } else {
        this.submission.fields.billDelivery.value = 'Deliver printed bill'
        this.contactDetails.fields.email.props.required = false
        this.contactDetails.fields.email.props.rules.splice(0, 20)
        this.contactDetails.fields.email.validate && this.$nextTick(() => this.contactDetails.fields.email.validate())
      }
    },
    'profile.fields.gender.value': function (val) {
      this.submission.fields.gender.value = val
      if (val === 'Male' || this.profile.fields.race.value === 'Malay') {
        this.profile.fields.maidenName.hide = true
      } else {
        this.profile.fields.maidenName.hide = false
      }
    },
    'profile.fields.race.value': function (val) {
      this.submission.fields.race.value = val
      if (val === 'Malay' || this.profile.fields.gender.value === 'Male') {
        this.profile.fields.maidenName.hide = true
      } else {
        this.profile.fields.maidenName.hide = false
      }
    },
    'contactDetails.fields.foreignerAddress.value': function (val) {
      this.submission.fields.foreignerAddress.value = val
        ? formatAddressForeigner(this.contactDetails.fields.foreignerAddress.fields)
        : this.foreignerAddress
    },
    'profile.fields.fcountry.value': function (val) {
      this.submission.fields.fcountry.value = val
    },
    'profile.fields.subid.value': function (val) {
      this.submission.fields.subid.value = val
      if (!val || val.length < 12) return

      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const myKad = {
        dob: val.substr(0, 6),
        state: val.substr(6, 2),
        gender: val.substr(11, 1) % 2 ? 'Male' : 'Female',
      }
      const yy = Number(myKad.dob.substr(0, 2))
      const mm = myKad.dob.substr(2, 2)
      const dd = myKad.dob.substr(4, 2)
      let year = 1900 + yy

      this.submission.fields.subid.value = myKad.dob + '-' + myKad.state + '-' + val.substr(8)
      if (now.year - year > 100) year = 2000 + yy
      myKad.dob = String(year) + '-' + mm + '-' + dd

      this.profile.fields.dob.validate && this.profile.fields.dob.validate()
      this.profile.fields.dob.value = myKad.dob

      this.profile.fields.gender.value = myKad.gender

      const state = getStateFromMyKad(val)
      if (state) {
        if (state === 'ArmedForcedID') {
          // this.profile.fields.idType.value = state
        } else {
          // this.profile.fields.idType.value = 'NewNRIC'
          // this.contactDetails.fields.state.value = state
        }
      } // else this.profile.fields.idType.value = 'NewNRIC'
    },
    'contactDetails.fields.billingAddress.value': function (val) {
      this.submission.fields.billingAddress.value = val
        ? formatAddressFamily(this.contactDetails.fields.billingAddress.fields)
        : this.address
    },
    'contactDetails.fields.street1.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.street2.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.city.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.postcode.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.state.value': function () {
      this.submission.fields.address.value = this.address
    },
    'contactDetails.fields.billingAddress.fields.address1.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.address2.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.city.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.postcode.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.state.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    foreignerStatus: function (val) {
      if (!val) return
      this.submission.fields.fstatus.value = val.text
    },
    'contactDetails.fields.fstreet1.value': function () {
      this.submission.fields.foreignerAddress.value = this.foreignerAddress
    },
    'contactDetails.fields.fstreet2.value': function () {
      this.submission.fields.foreignerAddress.value = this.foreignerAddress
    },
    'contactDetails.fields.fcity.value': function () {
      this.submission.fields.foreignerAddress.value = this.foreignerAddress
    },
    'contactDetails.fields.fpostalCode.value': function () {
      this.submission.fields.foreignerAddress.value = this.foreignerAddress
    },
    'contactDetails.fields.fstate.value': function () {
      this.submission.fields.foreignerAddress.value = this.foreignerAddress
    },
    'registrationDetails.fields.msisdnList.props.items': {
      handler: function () {
        const requiredEcash = this.requiredEcash
        this.registrationDetails.fields.requiredEcash.value = getRm(requiredEcash)
        if (requiredEcash > getNumeralFromRm(this.erecharge.value)) {
          this.registrationDetails.fields.requiredEcash.error = 'Insufficient balance!'
        } else {
          this.registrationDetails.fields.requiredEcash.error = null
        }
        this.registrationDetails.fields.requiredEcash.validate && this.registrationDetails.fields.requiredEcash.validate(true)
        const chargeable = this.chargeableAmount
        this.submission.fields.chargeable.value = getRm(chargeable)
      },
      deep: true,
    },
  },
  async mounted () {
    this.submission.fields.msisdnSummary.props.items = this.registrationDetails.fields.msisdnList.props.items
    this.contactDetails.fields.billingAddress.fields = this.billingAddress

    const params = createGetParams({
      erecharge: true,
    })
    try {
      const response = await this.$rest.get('getDealerResources.php', params)

      this.erecharge.error = null
      this.erecharge.value = getRm(response.data.erecharge)
      if (response.data.b10Promo) {
        this.alertText = response.data.b10Promo.notification
        this.notification = true
      }
    } catch (error) {
      this.erecharge.error = error.message
    }

    if (this.id) {
      this.title = 'Re-submit Mnp'
      this.breadcrumbs[1].text = 'Re-submit Mnp'
      this.registrationDetails.fields.msisdnList.props.mnpResubmit = true
      this.submission.fields.chargeable.hide = true
      const params = createGetParams({
        portId: this.id,
      })

      this.$rest.get('getMnpPayload.php', params).then(function (response) {
        const data = response.data

        this.registrationDetails.fields.mnpStatusInfo.value = data.mnpStatus
        this.registrationDetails.fields.mnpStatusInfo.info = data.mnpStatusInfo
        this.registrationDetails.fields.ugu.value = data.ugu
        this.registrationDetails.fields.donor.value = data.donor
        this.registrationDetails.fields.principal.value = data.mainMsisdn
        this.mainMsisdn = data.mainMsisdn
        this.list = data.msisdnList
        this.plan = data.planname
        this.donor = data.donor
        this.profile.fields.title.value = data.title
        this.profile.fields.name.value = data.name
        this.profile.fields.subid.value = data.subid
        this.regType = data.regtype
        this.profile.fields.dob.value = data.dob
        this.profile.fields.gender.value = data.value
        this.profile.fields.race.value = data.race
        this.profile.fields.maidenName.value = data.motherMaidenName
        data.msisdnList.forEach(item => {
          const newItem = Object.assign({
            detail: null,
            iccid: null,
            icon: null,
            iconClass: null,
            msisdn: null,
            resubmit: true,
          }, item)
          if (this.plan === 'SISWA_PLUS') {
            if (this.mainMsisdn === item.msisdn) {
              newItem.plan = 'SISWA_PLUS'
              newItem.price = 35
            } else {
              newItem.price = 10
              newItem.plan = 'SISWA_PLUS'
            }
          } else {
            if (this.mainMsisdn === item.msisdn) {
              newItem.plan = 'ONEFamily'
              newItem.price = 39
            } else {
              newItem.plan = 'ONEFamily'
              newItem.price = 10
            }
          }

          newItem.detail = item.msisdn + ', ' + this.plan
          newItem.icon = mnpIcon
          newItem.iconClass = mnpClass
          this.registrationDetails.fields.msisdnList.props.items.push(newItem)
        })
        this.contactDetails.fields.contactNumber.value = data.contactphone1
        this.contactDetails.fields.billDelivery.value = data.billDelivery
        this.contactDetails.fields.email.value = data.email
        this.contactDetails.fields.address1.value = data.address1
        this.contactDetails.fields.address2.value = data.address2
        this.contactDetails.fields.city.value = data.city
        this.contactDetails.fields.postcode.value = data.postcode
        this.contactDetails.fields.state.value = data.state
        this.foreignerInfo = data.foreignerInfo
        data.foreignerInfo.forEach(item => {
          // this.profile.fields.fstatus.value = item.status
          if (item.status === 'T') {
            this.profile.fields.fstatus.value = { text: 'Tourist', value: 'T' }
          } else if (item.status === 'W') {
            this.profile.fields.fstatus.value = { text: 'Worker', value: 'W' }
          } else if (item.status === 'S') {
            this.profile.fields.fstatus.value = { text: 'Student', value: 'S' }
          }
          this.profile.fields.fcountry.value = item.issueCountry
          this.contactDetails.fields.fstreet1.value = item.regAddress
          this.contactDetails.fields.fstreet2.value = item.regAddressL2
          this.contactDetails.fields.fcity.value = item.regCity
          this.contactDetails.fields.fpostalCode.value = item.regPostcode
          this.contactDetails.fields.fstate.value = item.regState
          this.regName = item.regName
          this.regRef = item.regRef
          this.subid = item.subid
        })
        if (this.foreignerInfo === '') {
          this.profile.fields.fstatus.hide = true
          this.profile.fields.fcountry.hide = true
          this.submission.fields.fstatus.hide = true
          this.submission.fields.fcountry.hide = true
          this.submission.fields.foreignerAddress.hide = true
        } else {
          this.profile.fields.fstatus.hide = false
          this.profile.fields.fcountry.hide = false
          this.submission.fields.fstatus.hide = false
          this.submission.fields.fcountry.hide = false
          this.submission.fields.foreignerAddress.hide = false
        }
        this.contactDetails.fields.billingAddress.fields = data.billingAddress
          ? Object.assign(this.contactDetails.fields.billingAddress.fields, {
            address1: data.billingAddress.address1,
            address2: data.billingAddress.address2,
            city: data.billingAddress.city,
            postcode: data.billingAddress.postcode,
            state: data.billingAddress.state,
          }) : null
      }.bind(this)).catch(() => {
      })
    }
  },
  methods: {
    agreePdpa: function (val) {
      this.pdpaDialog = false
      if (!val) {
        this.status = this.submission.status = 'error'
        this.submission.errorMessage = 'You must ask the customer to agree to the Personal Data Protection Act before submitting the registration.'
        this.submission.submit = false
      }
      this.agree = val
    },
    // Override
    submit: function () {
      this.pdpaDialog = true
    },
    async doSubmit () {
      const params = createPostParams({
        portReqFormId: this.id,
        ugu: this.registrationDetails.fields.ugu.value,
        donor: this.registrationDetails.fields.donor.value,
        title: this.profile.fields.title.value,
        name: this.profile.fields.name.value,
        subid: this.profile.fields.subid.value,
        msisdnList: this.list,
        mainMsisdn: this.mainMsisdn,
        planname: this.plan,
        withReader: false,
        regtype: this.regType,
        dob: this.profile.fields.dob.value,
        gender: this.profile.fields.gender.value,
        race: this.profile.fields.race.value,
        motherMaidenName: this.profile.fields.maidenName.value,
        contactphone1: this.contactDetails.fields.contactNumber.value,
        billDelivery: this.contactDetails.fields.billDelivery.value,
        email: this.contactDetails.fields.email.value,
        address1: this.contactDetails.fields.address1.value,
        address2: this.contactDetails.fields.address2.value,
        city: this.contactDetails.fields.city.value,
        postcode: this.contactDetails.fields.postcode.value,
        state: this.contactDetails.fields.state.value,
        billingAddress: this.contactDetails.fields.billingAddress.value
          ? Object.assign({}, {
            address1: this.contactDetails.fields.billingAddress.fields.address1.value,
            address2: this.contactDetails.fields.billingAddress.fields.address2.value,
            city: this.contactDetails.fields.billingAddress.fields.city.value,
            postcode: this.contactDetails.fields.billingAddress.fields.postcode.value,
            state: this.contactDetails.fields.billingAddress.fields.state.value,
          }) : null,
        foreignerInfo: [],
      })
      if (this.foreignerInfo) {
        params.foreignerInfo.push({
          issueCountry: this.profile.fields.fcountry.value,
          regAddress: this.contactDetails.fields.street1.value,
          regAddressL2: this.contactDetails.fields.street2.value,
          regCity: this.contactDetails.fields.city.value,
          regPostcode: this.contactDetails.fields.postalCode.value,
          regState: this.contactDetails.fields.state.value,
          regName: this.regName,
          regRef: this.regRef,
          status: this.profile.fields.fstatus.value.value,
          subid: this.subid,
        })
      }

      try {
        const response = await this.$rest.post('family/post.php/mnpFamilyLines', params)
        // const response = await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.alertText = 'Your request to register Family numbers was successfully submitted.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          if (this.isImpersonated()) {
            this.showSubmissionResponse(response.data)
          } else {
            this.downloadReceipt()
          }
        })
      } catch (e) {
        this.$nextTick(() => {
          this.status = this.submission.status = 'error'
          this.submission.errorMessage = e.message + ': ' + e.response.data
          this.submission.submit = false
          this.agree = false
        })
      }
    },
    async downloadReceipt () {
      let invoice = 'R-' + stringGen(9)
      invoice = invoice.toUpperCase()

      const payload = {
        invoice: invoice,
        mainMsisdn: this.mainMsisdn,
        name: this.profile.fields.name.value,
        street1: this.contactDetails.fields.address1.value,
        street2: this.contactDetails.fields.address2.value,
        city: this.contactDetails.fields.city.value,
        postalCode: this.contactDetails.fields.postcode.value,
        state: this.contactDetails.fields.state.value,
        items: [],
      }

      this.registrationDetails.fields.msisdnList.props.items.forEach(item => {
        payload.items.push({
          msisdn: item.msisdn,
          plan: this.plan,
        })
      })

      const data = process.env.NODE_ENV === 'production' ? { payload: payload }
        : { impersonate: 223992, payload: payload }
      /* eslint-disable no-empty */
      try {
        const response = await this.$rest({
          url: process.env.NODE_ENV === 'production' ? location.protocol + '//' + location.hostname + '/api/pdf/registrationFamilyReceipt.php'
            : 'http://onesys.onexox.my/pdf/registrationFamilyReceipt.php',
          method: 'POST',
          responseType: 'blob',
          data: data,
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', 'family_registration_receipt.pdf')
        document.body.appendChild(link)
        link.click()
      } catch (e) {}
    },
  },
}
